.App {
	background: linear-gradient(0.4turn, rgb(75, 128, 82), rgb(26, 84, 99));
	min-height: 100vh;
	padding-bottom: 20px;
}

.App-header {
	background-color: rgb(30, 34, 41);
	min-height: 10vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	font-weight: bold;
	padding: 5px 0;
	color: white;
}
